import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Container, Image, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Parse from 'parse';
import Navbarngl from '../navbarngl';
import Tops from '../top';
import AddProductButton from '../productbutton'; // Import corretto
import checkoutimage from '../contents/Payment-Checkout.webp'

const Shopss = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const ShopCard = Parse.Object.extend('ShopCard');
      const query = new Parse.Query(ShopCard);
      const results = await query.find();
      const fetchedProducts = results.map(product => ({
        title: product.get('title'),
        text: product.get('text'),
        imageUrl: product.get('imageUrl'),
        price: product.get('price'),
        sellerName: product.get('sellerName'),
        paymentLink: product.get('paymentLink'),
      }));
      setProducts(fetchedProducts);
    };

    fetchProducts();
  }, []);

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const handleAddProduct = (product) => {
    setProducts([...products, product]);
  };

  return (
    <div>
      <Navbarngl />
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
      <AddProductButton onAddProduct={handleAddProduct} />
        <h1 style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}>Lo store online di NGL Cila. </h1>
        <p style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}>Venduto da noi e da altri utenti, con cura e passione</p>
        
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {products.map((product, index) => (
          <Card key={index} style={{ width: '30rem', borderRadius: '30px', margin: '10px' }}>
            <Card.Img variant="top" src={product.imageUrl} style={{ borderRadius: '30px 30px 0 0' }} />
            <Card.Body>
              <Card.Title style={{ textAlign: 'center' }}>{product.title}</Card.Title>
              <Card.Text dangerouslySetInnerHTML={{ __html: product.text }}></Card.Text>
              <Card.Text><strong>{product.price}</strong></Card.Text>
              <Card.Text><em>Venduto da: {product.sellerName}</em></Card.Text>
              <Button variant="primary" onClick={() => handleShowModal(product)}>Acquista Ora</Button>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Container>
      
      <p> Pagamenti sicuri al 100%, supportiamo la maggioranza dei metodi di pagamento, rimborso facile al 100% assicurato</p>
      <Image src={checkoutimage} fluid />
      </Container>
      <Tops />

      {selectedProduct && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={selectedProduct.imageUrl} alt={selectedProduct.title} style={{ width: '100%', marginBottom: '10px' }} />
            <p dangerouslySetInnerHTML={{ __html: selectedProduct.text }}></p>
            <p><strong>{selectedProduct.price}</strong></p>
            <p><em>Venduto da: {selectedProduct.sellerName}</em></p>
            <Form>
              {selectedProduct.paymentLink && (
                <Button variant="secondary" href={selectedProduct.paymentLink} target="_blank">Clicca per ordinare</Button>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Shopss;





