import React, { useState, useEffect } from 'react';
import { Container, Alert } from 'react-bootstrap';

const urlNotizie = 'http://www.viaggiatreno.it/infomobilita/resteasy/viaggiatreno/news/0/it';

function NotizieFerrovie() {
  const [notizie, setNotizie] = useState([]);

  useEffect(() => {
    fetch(urlNotizie)
      .then(response => response.json())
      .then(data => setNotizie(data))
      .catch(error => console.error("Errore nel fetch delle notizie:", error));
  }, []);

  return (
    <Container style={{ overflow: 'hidden', height: '60px', position: 'relative', backgroundColor: '#f8f9fa', padding: '10px 0' }}>
      <div style={{ 
        display: 'flex', 
        whiteSpace: 'nowrap', 
        animation: 'scroll 40s linear infinite', 
        alignItems: 'center' // Per allineare verticalmente gli alert
      }}>
        {notizie.map((notizia, index) => (
          <Alert 
            key={index} 
            variant="info" 
            style={{ 
              display: 'inline-block', 
              marginRight: '50px', // Spazio tra le notizie
              padding: '10px 20px', // Aggiungi padding per maggiore visibilità
              minWidth: 'fit-content' // Assicura che l'intero contenuto sia visibile
            }}
          >
            {notizia.titolo}
          </Alert>
        ))}
      </div>
      <style>
        {`
        @keyframes scroll {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(-100%);
          }
        }
        `}
      </style>
    </Container>
  );
}

export default NotizieFerrovie;





