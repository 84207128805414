import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import stations from "./stations.json";
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function ListaTreni() {
    const [treni, setTreni] = useState([]);
    const [stazionePartenza, setStazionePartenza] = useState([]);
    const [orarioRicerca, setOrarioRicerca] = useState(new Date());
    const [loading, setLoading] = useState(false);  // Nuovo stato di caricamento

    const handleSearch = () => {
        if (!stazionePartenza[0]) {
            alert("Per favore seleziona una stazione.");
            return;
        }

        console.log('Stazione di Partenza:', stazionePartenza);

        const partenzaId = stazionePartenza[0]?.code;

        if (!partenzaId) {
            alert("ID della stazione di partenza non trovato.");
            return;
        }

        console.log('Partenza ID:', partenzaId);  // Log ID della stazione

        setLoading(true);  // Imposta lo stato di caricamento a true

        axios.get(`https://trenitaliapijs-fuzf.onrender.com/stations/${partenzaId}/departures`)
            .then(response => {
                const treniAggiornati = response.data.map(treno => {
                    const orarioPartenza = new Date(treno.orarioPartenza);
                    const tempoTrascorso = (orarioRicerca - orarioPartenza) / (1000 * 60); // tempo trascorso in minuti

                    if (!treno.circolante && treno.nonPartito) {
                        treno.statoTreno = "Non partito";
                    } else if (!treno.circolante && tempoTrascorso > 0 && tempoTrascorso <= 30 && treno.ritardo === 0) {
                        treno.statoTreno = "Cancellato/Soppresso";
                    } else if (orarioRicerca > orarioPartenza && !treno.binarioEffettivoPartenzaDescrizione && treno.ritardo === 0) {
                        treno.statoTreno = "CANCELLATO";
                    }
                    return treno;
                });
                setTreni(treniAggiornati);
                setLoading(false);  // Imposta lo stato di caricamento a false
            })
            .catch(error => {
                console.error("C'è stato un errore nel recupero dei treni!", error);
                console.log('Error Response:', error.response);
                alert(`Errore nel recupero dei treni: ${error.message}`);
                setLoading(false);  // Imposta lo stato di caricamento a false
            });
    };

    return (
        <Container>
            <h2>Trova Treni</h2>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="stazionePartenza">
                            <Form.Label>Stazione di Partenza</Form.Label>
                            <Typeahead
                                id="stazione-partenza"
                                labelKey="long_name"
                                onChange={setStazionePartenza}
                                options={stations}
                                placeholder="Cerca stazione"
                                selected={stazionePartenza}
                                clearButton
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" onClick={() => { setOrarioRicerca(new Date()); handleSearch(); }}>Cerca</Button>
            </Form>

            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    <p>Attendere prego...</p>
                </div>
            ) : (
                <>
                    <h2 className="mt-4">Lista dei Treni</h2>
                    <Row>
                        {treni.map(treno => (
                            <Col sm={12} md={6} lg={4} className="mb-4" key={treno.numeroTreno}>
                                <Card>
                                    <Card.Body>
                                        <img src={treno.iconaTreno} alt="Icona Treno" style={{ width: '50px', marginBottom: '10px' }} />
                                        <Card.Title>Treno {treno.compNumeroTreno}</Card.Title>
                                        <Card.Text>
                                            <strong>Tipologia:</strong> {treno.compTipologiaTreno}<br />
                                            <strong>Diretto a:</strong> {treno.destinazione}<br />
                                            <strong>Ora Partenza:</strong> {new Date(treno.orarioPartenza).toLocaleTimeString()}<br />
                                            <strong>Numero del Treno:</strong> {treno.numeroTreno}<br />
                                            <strong>Ritardo:</strong> {treno.ritardo} minuti<br />
                                            <strong>Data di Partenza:</strong> {new Date(treno.dataPartenzaTreno).toLocaleDateString()}<br />
                                            <strong>Stato Treno:</strong> {treno.statoTreno ? treno.statoTreno : "Informazioni non disponibili"}<br />
                                            <strong>Binario Programmato:</strong> {treno.binarioProgrammato}<br />
                                            <strong>Binario Reale:</strong> {treno.binarioReale}<br />
                                            <strong>Categoria:</strong> {treno.categoria}<br />
                                            <strong>Descrizione Categoria:</strong> {treno.categoriaDescrizione}<br />
                                            <strong>Binario Effettivo Partenza:</strong> {treno.binarioEffettivoPartenzaDescrizione}
                                        </Card.Text>
                                        {treno.statoTreno === "CANCELLATO" && <p style={{ color: 'red' }}>CANCELLATO</p>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </Container>
    );
}














