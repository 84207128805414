import React from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import { Container, Image, Row, Col } from "react-bootstrap";
import logo from '../contents/ngllogo.jpg'


function Human() {
    return (
        <div style={{fontFamily: 'Inter, sans-serif'}}>
            <Navbarngl />
            <Container>
            <h1 style={{fontFamily: 'Inter, sans-serif', fontSize: '48px', fontWeight: 'bold'}}>All'ITIS CARAMUEL APRE UN NUOVO LABORATORIO DI ROBOTICA</h1>
            <p style={{fonntFamily: 'Inter, sans-serif', fontSize: '18px'}}><b>C’è chi vi dà una mano, noi vi diamo tutto il braccio: al via lo Human and Technology HUB!</b><br />
            
<em>Il futuro passa da Vigevano: inaugurazione del laboratorio tecnologico con Comau, WINDTRE e Manpower presso la sede dell’istituto Caramuel Roncalli il 21 dicembre</em> <br />

Vigevano, 02 dicembre 2024 – Il futuro è a portata di braccio, e non stiamo parlando di una semplice metafora. L’Istituto Caramuel Roncalli di Vigevano inaugura il nuovo <b>Human and Technology HUB</b>, un laboratorio tecnologico d’avanguardia dove gli studenti potranno esplorare il mondo della robotica grazie a <b>e.DO, il braccio robotico di piccola taglia sviluppato da Comau</b>, progettato per rendere l’apprendimento STEM (Science, Technology, Engineering, Mathematics) accessibile e coinvolgente. <br />
<br />

L’evento nasce dalla collaborazione tra <b>Comau, WINDTRE e Manpower, con il patrocinio del Comune di Vigevano,</b> e si propone di connettere il mondo della scuola a quello del lavoro <b>attraverso un’esperienza interattiva, divertente e formativa.</b></p>
<Image src="https://scaling.spaggiari.eu/PVIT0006/testo/389.png&rs=%2FtccTw2MgxYfdxRYmYOB6Pk9jQH5POS%2FquVc8aOi3ns2htM1BhF%2Fr5nAtRVDWvfTyMAZSK1CdbWaDHnglQjglAuFwI5cB%2FVmg%2FuX4At01ifvHVhzR520%2FYme%2BqShUDP%2B9Qj7hNmcQs3PUZ%2B9YD5vdA%3D%3D" fluid alt="Descrizione dell'immagine" />
<hr />

<p><strong>Un laboratorio per il futuro: protagonisti gli studenti</strong></p>
      <p>
        Il laboratorio ospiterà e.DO, un braccio robotico a sei assi dotato di pinza, progettato per avvicinare i giovani alla robotica, al coding e alla matematica in maniera semplice e interattiva. Facilmente programmabile da tablet tramite un’App, e.DO non è solo uno strumento tecnologico, ma un’opportunità per sviluppare competenze trasversali fondamentali come il problem-solving, la cooperazione e la creatività, in un ambiente inclusivo e motivante.
      </p>
      <p>
        "Non si tratta solo di imparare a usare un robot, ma di scoprire come la tecnologia possa diventare un alleato per costruire il proprio futuro", sottolineano gli organizzatori.
      </p>
      <p><strong>Un programma ricco e interattivo</strong></p>
      <p>
        La giornata, che sarà condotta da Petra Loreggian, nota speaker di RDS, con la sua energia e il suo tocco ironico guiderà il pubblico tra incontri, laboratori e momenti di intrattenimento. La sua presenza renderà l’evento ancora più coinvolgente e dinamico, creando un’atmosfera leggera e interattiva.
      </p>
      <p>
        Inizio previsto per le ore 10:00 e, tra i momenti principali:
      </p>
      <ul>
        <li>Intervento speciale di Max Temporelli, fisico e divulgatore scientifico, noto podcaster e storyteller tecnologico, che racconterà con il suo stile unico il legame tra umanità e innovazione.</li>
        <li>Workshop curati da WINDTRE e Manpower sull’uso consapevole della tecnologia e competenze del futuro.</li>
        <li>Laboratori live con e.DO: gli studenti, su prenotazione, potranno testare e programmare il braccio robotico in sessioni pratiche di 30 minuti.</li>
        <li>Simulatori RACER: uno spazio per mettere alla prova le soft skill in scenari simulati di gara sportiva.</li>
      </ul>
      <p><strong>A chi è rivolto l’evento</strong></p>
      <p>
        L’Human and Technology HUB si rivolge a chiunque sia alla ricerca di una nuova prospettiva, sia nello studio che nel lavoro.
      </p>
      <ul>
        <li>Per le studentesse e gli studenti sarà un’occasione unica per sperimentare strumenti innovativi, con il supporto di esperti, e scoprire nuovi ambiti di apprendimento e un’opportunità per avvicinarsi al mondo STEM con attività accessibili a tutte e tutti, in un ambiente inclusivo e stimolante.</li>
        <li>Per i giovani in cerca di orientamento: un momento per esplorare le possibilità offerte dalle competenze tecnologiche e trasversali, mettendosi alla prova con laboratori e simulatori.</li>
        <li>Per i genitori: uno sguardo concreto sulle opportunità che tecnologia e formazione possono offrire ai loro figli.</li>
      </ul>
      <p><strong>Come partecipare</strong></p>
      <p>
        Le iscrizioni per i laboratori pratici sono già aperte. Basta scansionare il QR Code presente nella locandina dell’evento per prenotare un posto e immergersi in un’esperienza unica, dove scuola, aziende e tecnologia collaborano per dare forma al futuro.
      </p>
      <a href="https://www.eventbrite.it/e/biglietti-human-and-technology-hub-1081575695019?aff=oddtdtcreator">www.eventbrite.it/e/biglietti-human-and-technology-hub-1081575695019?aff=oddtdtcreator</a>
      <p>Non perdete questa occasione di prendere il futuro per tutto il braccio!</p>
      <p><strong>Evento a cura di Humor Business Experience (HBE)</strong></p>
      <p>Per ulteriori dettagli sull’evento e interviste: <a href="mailto:info@humorbusiness.it">info@humorbusiness.it</a></p>
      <p>Per maggiori informazioni visitare la pagina originale: <a href="https://www.caramuelroncalli.it/pagine/al-via-lo-human-and-technology-hub?fbclid=PAZXh0bgNhZW0CMTEAAaZe65y5JXI7YCE7QWZDFWqk7Q5HPilOQgiYBSvBgH-qIBDLHL6T-KENszg_aem_jS7LFLZMqeBJmE4MmJeavA">EVENTO APERTURA TECNOLOGY HUB</a></p>
      <Row>
        <Col xs={12} md={6}>
          <Image 
            src="https://scaling.spaggiari.eu/PVIT0006/testo/391.png&rs=%2FtccTw2MgxYfdxRYmYOB6Pk9jQH5POS%2FquVc8aOi3ns2htM1BhF%2Fr5nAtRVDWvfTyMAZSK1CdbWaDHnglQjglAuFwI5cB%2FVmg%2FuX4At01ifvHVhzR520%2FYme%2BqShUDP%2B9Qj7hNmcQs3PUZ%2B9YD5vdA%3D%3D" 
            fluid 
            alt="Descrizione dell'immagine comune di Vigevano" 
            className="w-25" 
          />
          <p>Comune Di Vigevano</p>
        </Col>
        <Col xs={12} md={6}>
          <Image 
            src={logo}  // Sostituisci con il percorso corretto del tuo logo
            fluid 
            alt="Descrizione del logo" 
            className="w-25" 
          />
          <p>Logo NGL Cila</p>
        </Col>
      </Row>


            </Container>
            <img src="https://scaling.spaggiari.eu/PVIT0006/testo/392.png&rs=%2FtccTw2MgxYfdxRYmYOB6Pk9jQH5POS%2FquVc8aOi3ns2htM1BhF%2Fr5nAtRVDWvfTyMAZSK1CdbWaDHnglQjglAuFwI5cB%2FVmg%2FuX4At01ifvHVhzR520%2FYme%2BqShUDP%2B9Qj7hNmcQs3PUZ%2B9YD5vdA%3D%3D" fluid alt="Descrizione dell'immagine comune di vigevano" style={{ width: '650px', height: 'auto', marginLeft: '500px' }}/>
            <hr />
            <br />
            <br />
            <br />
            <br />
            

            <Tops />
        </div>
    );
}

export default Human;