import React from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import '../contents/autoguidovie.css';

const LineCard = ({ name, from, to, link }) => (
  <Card style={{ width: '18rem', margin: '10px' }}>
    <Card.Img variant="top" src="https://pavia.autoguidovie.it/image/b1//190920_box%20extraurbano%20pavia%20per%20sito.jpg" />
    <Card.Body>
      <Card.Title>{name}</Card.Title>
      <Card.Text>
        Da: {from} <br />
        A: {to}
      </Card.Text>
      <Button variant="primary" href={link} target="_blank">Vedi orari</Button>
    </Card.Body>
  </Card>
);

const lines = [
    { name: 'Linea 87', from: 'Vigevano', to: 'Cassolnovo - Trecate - Novara Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%2087_FER%20INV%20s.pdf' },
    { name: 'Linea 105', from: 'Garlasco S. Biagio', to: 'Zerbolò - Pavia Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20105_FER%20INV%20s.pdf' },
    { name: 'Linea 106', from: 'Vigevano FS', to: 'Cilavegna - Mortara FS', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20106_FER%20INV%20s.pdf' },
    { name: 'Linea 107', from: 'Mortara FS', to: 'Garlasco/Vigevano', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20107_FER%20INV%20s.pdf' },
    { name: 'Linea 108', from: 'Dorno', to: 'Garlasco - Pavia Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20108_FER%20INV%20s.pdf' },
    { name: 'Linea 110', from: 'Vigevano', to: 'Garlasco - Pavia Autostazione (via Gambolò/B.go S. Siro)', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20110_FER%20INV%20s.pdf' },
    { name: 'Linea 111', from: 'Mede FS', to: 'Pieve C. - Frascarolo - Valenza', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20111_FER%20INV%20s.pdf' },
    { name: 'Linea 112', from: 'Sannazzaro', to: 'Mezzana Bigli - Casale Monferrato', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20112_FER%20INV%20s.pdf' },
    { name: 'Linea 115', from: 'Pieve C.', to: 'Mede FS - Garlasco', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20115_FER%20INV%20s.pdf' },
    { name: 'Linea 125', from: 'Mortara FS', to: 'Mede FS - Pieve del Cairo - Voghera Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20125_FER%20INV%20s.pdf' },
    { name: 'Linea 135', from: 'Vigevano', to: 'Gambolò Garbana - Mortara FS', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20135_FER%20INV%20s.pdf' },
    { name: 'Linea 145', from: 'Vigevano FS', to: 'Gambolò', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20145_FER%20INV%20s.pdf' },
    { name: 'Linea 153', from: 'Pieve del Cairo', to: 'Sannazzaro - Garlasco - Milano Famagosta M2', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20153_FER%20INV%20s.pdf' },
    { name: 'Linea 154', from: 'Voghera Autostazione', to: 'Pieve del Cairo - Sannazzaro - Garlasco - Vigevano', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20154_FER%20INV%20s.pdf' },
    { name: 'Linea 157', from: 'Mede FS', to: 'Zeme - Casale Monferrato', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20157_FER%20INV%20s.pdf' },
    { name: 'Linea 167', from: 'Mede FS', to: 'Gambolò - Vigevano/Valeggio - Garlasco', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20167_FER%20INV%20s.pdf' },
    { name: 'Linea 168', from: 'Dorno', to: 'Sannazzaro - Zinasco - Pavia Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20168_FER%20INV%20s.pdf' },
  ];
  

function Orarioautoguidovie() {
  return (
    <div>
      <Navbarngl />
      <Container>
        <div style={{ fontFamily: 'Inter, sans-serif' }}>
          <h1 style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontFamily: 'Inter, sans-serif' }}>
            Linee Extraurbane Autoguidovie
          </h1>
          <p style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontFamily: 'Inter, sans-serif' }}>Qui trovi tutti gli orari e le informazioni per tutte le linee extraurbane Autoguidovie dell'area Lomellina.</p>
          <Row>
            {lines.map((line, index) => (
              <Col key={index} sm={12} md={6} lg={4}>
                <LineCard name={line.name} from={line.from} to={line.to} link={line.link} />
              </Col>
            ))}
          </Row>
          <div style={{ marginTop: '30px', textAlign: 'center' }}>
            <h2>Mappa dell'area lomellina</h2>
            <img src="https://moovitapp.com/index/public-transit-maps/image/?mapImage=v4%2Fmaps%2FItaly_Milano_e_Lombardia_Pavia_suburban_lines%2FItaly_Milano_e_Lombardia_Pavia_suburban_lines_3-desktop-.jpg" alt="Mappa dell'area lomellina" />
          </div>
        </div>
      </Container>
      <Tops />
    </div>
  );
}

export default Orarioautoguidovie;












