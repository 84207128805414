import React from 'react';
import { Form, Button, Container } from 'react-bootstrap';

function InserisciCodiceTreno() {
  return (
    <Container style={{ marginTop: '20px' }}>
      <h2>Inserisci il Codice del Treno</h2>
      <Form>
        <Form.Group controlId="codiceTreno">
          <Form.Label>Codice del Treno</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Inserisci il codice del treno" 
            style={{ width: '300px', margin: '0 auto' }}
          />
        </Form.Group>
        <Button variant="primary" style={{ marginTop: '10px' }}>Conferma</Button>
      </Form>
    </Container>
  );
}

export default InserisciCodiceTreno;
