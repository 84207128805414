import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { FaVideo, FaAlignLeft } from 'react-icons/fa';
import { handleVideoSubmit } from './js/videoUtils';

function VideoForm({ onVideoAdded }) {
    const [description, setDescription] = useState('');
    const [video, setVideo] = useState(null);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    return (
        <div>
            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Col md={6}>
                        {showAlert && <Alert variant={message.includes('successo') ? 'success' : 'danger'}>{message}</Alert>}
                        <Form onSubmit={(e) => handleVideoSubmit(e, description, video, setMessage, setShowAlert, onVideoAdded)}>
                            <Form.Group controlId="formDescription">
                                <Form.Label><FaAlignLeft /> Descrizione</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formVideo">
                                <Form.Label><FaVideo /> Video</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setVideo(e.target.files[0])}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3">
                                Salva
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default VideoForm;
