import React, { useEffect, useState } from 'react';
import Navbarngl from "../navbarngl";
import Tops from "../top";
import Parse from 'parse';
import '../contents/video.css'; // Importa il file CSS

function Video() {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            const VideoData = Parse.Object.extend('VideoData');
            const query = new Parse.Query(VideoData);
            try {
                const results = await query.find();
                const videoList = results.map(video => ({
                    id: video.id,
                    url: video.get('videoUrl'),
                    description: video.get('description'),
                }));
                setVideos(videoList);
            } catch (error) {
                console.error('Error while fetching video data: ', error);
            }
        };

        fetchVideos();
    }, []);

    return (
        <div>
            <Navbarngl />
            <h1 style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}>Video di NGL Cila</h1>
            <p style={{ textAlign: 'center', fontFamily: 'Inter, sans-serif' }}>Esplora tutti i video/riprese reportate da NGL Cila. Le puoi trovare su Instagram, TikTok e qua</p>
            
            <div className="container-video">
                {videos.map(video => (
                    <div key={video.id} className="video-item-video">
                        <video controls className="video" src={video.url} />
                        <p className="video-description-video" style={{ fontFamily: 'Inter, sans-serif' }}>{video.description}</p>
                    </div>
                ))}
            </div>
            <Tops />
        </div>
    );
}

export default Video;


