import React, { useState } from "react";
import Navbarngl from "../navbarngl";
import Tops from "../top";
import { Container, Card, Row, Col } from 'react-bootstrap';
import { FaTrain, FaInfoCircle, FaClock, FaSearch, FaNewspaper, FaTable } from 'react-icons/fa';
import ListaStazioni from '../listaStazioni';
import InserisciCodiceTreno from '../InserisciCodiceTreno'; // Assicurati di aggiornare il percorso corretto
import NotizieFerrovie from '../NotizieRFI'; // Assicurati di aggiornare il percorso corretto

const services = [
  { name: 'Trova i treni in base alla stazione', icon: <FaSearch /> },
  { name: 'Stato del treno', icon: <FaTrain /> },
  { name: 'Dettagli sul treno in base al codice', icon: <FaInfoCircle /> },
  { name: 'Trovare gli orari', icon: <FaClock /> },
  { name: 'Mostra Notizie RFI', icon: <FaNewspaper /> },
  { name: 'Mostra tabellone real Time', icon: <FaTable /> },
];

function ServiceSelector() {
  const [selectedService, setSelectedService] = useState(null);

  const renderSelectedComponent = () => {
    switch (selectedService) {
      case 'Dettagli sul treno in base al codice':
        return <InserisciCodiceTreno />;
      case 'Mostra Notizie RFI':
        return <NotizieFerrovie />;
      default:
        return <ListaStazioni />;
    }
  };

  return (
    <div>
      <Navbarngl />
      <Container>
        <div style={{ fontFamily: 'Inter, sans-serif' }}>
          <h1 style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontFamily: 'Inter, sans-serif' }}>
            Seleziona un Servizio
          </h1>
          <Row>
            {services.map((service, index) => (
              <Col key={index} sm={12} md={6} lg={4}>
                <Card 
                  style={{ 
                    width: '18rem', 
                    margin: '10px', 
                    cursor: 'pointer', 
                    transition: 'transform 0.2s', 
                    textAlign: 'center' 
                  }} 
                  onClick={() => setSelectedService(service.name)}
                >
                  <Card.Body>
                    <div style={{ fontSize: '2rem', marginBottom: '10px' }}>
                      {service.icon}
                    </div>
                    <Card.Title>{service.name}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          {selectedService && renderSelectedComponent()}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Tops />
    </div>
  );
}

export default ServiceSelector;



