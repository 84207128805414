import React, { useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import icona from './contents/favicon.png';
import icona2 from './contents/logospotted.jpg';
import icona3 from './contents/image3.jpg';
import logo4 from './contents/logo4.jpg';
import logo5 from './contents/logo5.jpg';
import logo6 from './contents/logo6.jpg';

function Partnership() {
    const partners = [
        
        {
            name: "Spotted dell' ITIS Caramuel",
            description: "Il profilo gossip e notizie ufficiale del ITIS Caramuel di Vigevano, che collabora con noi e il nostro team.",
            logo: icona2,
            link: "https://instagram.com/spotted.caramuel_"
        },
        {
            name: "Vige Question",
            description: "Un nuovo profilo gossip di Vigevano, che ha appena iniziato la sua aventura e collabora con il nostro team su argomenti utili.",
            logo: icona3,
            link: "https://www.instagram.com/vige_question?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        },
        {
            name: " Crazy Lista",
            description: "I nuovi rappresentanti dell'ITIS Caramuel",
            logo: 'https://cdn.inflact.com/media/464219656_1312023189962865_4442286429777790075_n.jpg?url=https%3A%2F%2Fscontent.cdninstagram.com%2Fv%2Ft51.2885-19%2F464219656_1312023189962865_4442286429777790075_n.jpg%3Fstp%3Ddst-jpg_e0_s150x150%26_nc_ht%3Dinstagram.fhan20-1.fna.fbcdn.net%26_nc_cat%3D109%26_nc_ohc%3DBNSjk6pAz_oQ7kNvgFqr1_r%26_nc_gid%3De8b5f930767145e2872a79b01c9ca9d3%26edm%3DAOQ1c0wBAAAA%26ccb%3D7-5%26oh%3D00_AYDMzm4fNc7ZuxkykqhjKN9z4Iy1M1wAifPehPapofzIMQ%26oe%3D674693CA%26_nc_sid%3D8b3546&time=1732302000&key=c8411cbb7b0139073d836aede9e3572f',
            link: "https://www.instagram.com/crazy.lista?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        },
        
        
    ];

    const [selectedPartner, setSelectedPartner] = useState(null);

    const handleCardClick = (index) => {
        setSelectedPartner(index);
    }

    return (
        <Container className="my-5">
            <h1 style={{ fontFamily: 'Inter, sans-serif', animation: 'fadeInDown 1s', color: 'black' }}>I nostri partner</h1>
            <Row>
                {partners.map((partner, index) => (
                    <Col key={index} md={4} className="mb-4 d-flex">
                        <div 
                            className={`partner-card p-3 d-flex flex-row align-items-center ${selectedPartner === index ? 'active' : ''}`} 
                            style={{ animation: 'zoomIn 1s', backgroundColor: 'black', color: 'white' }}
                            onClick={() => handleCardClick(index)}
                        >
                            <Image src={partner.logo} roundedCircle alt={`${partner.name} Logo`} className="mr-4" style={{ width: '80px', height: '80px', marginRight: '20px' }} />
                            <div style={{ paddingLeft: '20px' }}>
                                <h3 style={{ fontFamily: 'Inter, sans-serif' }}>{partner.name}</h3>
                                <p style={{ fontFamily: 'Inter, sans-serif' }}>{partner.description}</p>
                                <Button variant="primary" href={partner.link} target="_blank" className="mt-auto d-flex align-items-center" style={{ borderRadius: '50px', fontFamily: 'Inter, sans-serif', backgroundColor:'wellow' }}>
                                    <FaInstagram className="mr-2" /> Visita il Profilo Instagram
                                </Button>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <style jsx>{`
                @keyframes fadeInDown {
                    from {
                        opacity: 0;
                        transform: translateY(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes zoomIn {
                    from {
                        opacity: 0;
                        transform: scale(0.5);
                    }
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                .partner-card {
                    background-color: black; /* Cambia lo sfondo in nero */
                    color: white; /* Cambia il testo in bianco */
                    border: 1px solid #ddd;
                    border-radius: 16px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px;
                    cursor: pointer; /* Aggiungi un cursore pointer */
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                }

                .partner-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                }

                .partner-card.active {
                    animation: fadeIn 1s;
                }

                @media (max-width: 768px) {
                    .partner-card {
                        flex-direction: column;
                        text-align: center;
                    }
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            `}</style>
        </Container>
    );
}

export default Partnership;








