import React from 'react';
import { Card, Button, Container } from 'react-bootstrap';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import nglvige from './contents/nglvige.png';

const StyledContainer = styled(Container)`
  margin-top: 80px;
`;

const StyledCard = styled(Card)`
  background-color: #000;
  color: white;
  position: relative;
  transition: transform 0.3s ease-in-out;
  border-radius: 16px;
  &:hover {
    transform: scale(1.05);
  }
`;

const StyledHeader = styled(Card.Header)`
  color: white;
  font-family: 'Inter, sans-serif';
  animation: fadeIn 1s;
  text-align: center;
  font-size: 2em;
`;

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  animation: bounceIn 1s;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  animation: rotateIn 2s;
  border-radius: 50%;
`;

const StyledText = styled(Card.Text)`
  color: white;
  font-family: 'Inter, sans-serif';
  animation: fadeIn 1s;
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  background-color: #0056b3;
  color: white;
  animation: fadeIn 1s;
  transition: background-color 0.3s ease-in-out;
  border-radius: 50px;
  padding: 10px 20px;
  &:hover {
    background-color: #004080;
  }
`;

const HumanExplore = () => {
  return (
    <StyledContainer>
      <StyledCard>
        <StyledHeader style={{ animation: 'fadeIn 1s', fontFamily: 'Inter, sans-serif' }}>SCOPRI IL NUOVO LABORATORIO DI ROBOTICA A VIGEVANO</StyledHeader>
        <Card.Body>
          <StyledLogo>
            <StyledImage src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7r43g9l-zjmUoyWh-4ECwzMWS_uoevG1U3g&s' alt="Logo" />
          </StyledLogo>
          <StyledText style={{ animation: 'fadeIn 1s', fontFamily: 'Inter, sans-serif' }}>
            SCOPRI DI PIU CLICCANDO SUL LINK QUA SOTTO
          </StyledText>
          <div className="d-flex justify-content-center mt-4">
            <StyledButton as='a' href='/human-lab' target="_blank" style={{ animation: 'fadeIn 1s', fontFamily: 'Inter, sans-serif' }}>Scopri di più</StyledButton>
          </div>
        </Card.Body>
      </StyledCard>
    </StyledContainer>
  );
};

// Animations
const styles = {
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  '@keyframes bounceIn': {
    '0%': { transform: 'scale(0.5)', opacity: 0 },
    '100%': { transform: 'scale(1)', opacity: 1 }
  },
  '@keyframes rotateIn': {
    '0%': { transform: 'rotate(-360deg)', opacity: 0 },
    '100%': { transform: 'rotate(0deg)', opacity: 1 }
  }
};

export default HumanExplore;