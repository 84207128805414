import React, { useState, useEffect, useCallback } from 'react';
import { ref, get, set, query, push, orderByChild, equalTo } from "firebase/database";
import { db } from "./js/firebase";
import { Modal } from 'react-bootstrap';
import './contents/banbackground.css'; // Assicurati di creare questo file CSS

const UserAccessData = ({ onAccessDataSaved }) => {
  const [ipAddress, setIpAddress] = useState('');
  const [browserInfo] = useState({
    browserName: navigator.appName,
    browserVersion: navigator.appVersion,
    os: navigator.platform,
    language: navigator.language
  });
  const [additionalData] = useState({
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    userAgent: navigator.userAgent
  });
  const [showBanModal, setShowBanModal] = useState(false);
  const [banMessage, setBanMessage] = useState('');
  const [isBanned, setIsBanned] = useState(false);

  const fetchIpAddress = useCallback(async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      console.log('Indirizzo IP ottenuto:', data.ip);
      setIpAddress(data.ip);
    } catch (error) {
      console.error('Errore durante il recupero dell\'indirizzo IP:', error);
    }
  }, []);

  const saveAccessData = useCallback(async () => {
    const accessData = {
      dateTime: new Date().toISOString(),
      ipAddress,
      browserInfo,
      additionalData
    };

    try {
      const newAccessDataRef = push(ref(db, 'AccessData'));
      await set(newAccessDataRef, accessData);
      console.log('Dati di accesso salvati con successo');
      if (onAccessDataSaved) {
        onAccessDataSaved();
      }
    } catch (error) {
      console.error('Errore durante il salvataggio dei dati:', error);
    }
  }, [ipAddress, browserInfo, additionalData, onAccessDataSaved]);

  const checkBannedIP = useCallback(async (ip) => {
    const bannedIPsRef = query(ref(db, 'BannedIPs'), orderByChild('ipAddress'), equalTo(ip));
    try {
      const snapshot = await get(bannedIPsRef);
      if (snapshot.exists()) {
        const data = Object.values(snapshot.val())[0];
        const reason = data.reason;
        const expiryDate = data.expiryDate ? new Date(data.expiryDate) : null;
        const now = new Date();
        console.log('Dati del ban ottenuti:', { data, now });
        if (expiryDate && expiryDate < now) {
          await saveAccessData(); // IP non più bannato, salva i dati di accesso
        } else {
          const expiryMessage = expiryDate ? `Il ban scade il: ${expiryDate.toLocaleDateString()}` : 'Ban permanente';
          setBanMessage(`
            <h1 style="color: black; font-weight: bold;">Sei stato bannato</h1>
<p style="color: black">Abbiamo sospeso il tuo account per violazione delle linee guida della community.</p>
<p style="color: black">Motivazione: ${reason}</p>
<p style="color: black">${expiryMessage}</p>
          `);
          setShowBanModal(true);
          setIsBanned(true);
        }
      } else {
        await saveAccessData(); // IP non bannato, salva i dati di accesso
      }
    } catch (error) {
      console.error('Errore durante la verifica dell\'IP bannato:', error);
    }
  }, [saveAccessData]);

  useEffect(() => {
    fetchIpAddress();
  }, [fetchIpAddress]);

  useEffect(() => {
    if (ipAddress) {
      checkBannedIP(ipAddress);
    }
  }, [ipAddress, checkBannedIP]);

  useEffect(() => {
    if (isBanned) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isBanned]);

  return (
    <div>
      <Modal show={showBanModal} centered>
        <Modal.Header>
          <Modal.Title style={{color: 'black'}}>
            ⚠️Accesso Negato⚠️
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: banMessage }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserAccessData;


























